<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="vendors"
                  item-text="supplier_name"
                  item-value="id"
                  return-object
                  v-model="vendor"
                  @change="onChangeVendor"
                  :rules="[v => !!v || 'Vendor is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  :rules="[v => !!v || 'Invoice Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>PO Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="purchaseOrders"
                  item-text="name"
                  return-object
                  v-model="purchaseOrderVendor"
                  :rules="[v => !!v || 'PO is required']"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.remark"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" class="py-0">
                <v-btn color="success" @click="addPurchaseOrder" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn color="primary" @click="viewTablePo">
                  <v-icon>
                    mdi-file-table-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                    @change="onInputInvoiceDuration"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Received Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceReceivedDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceReceivedDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceReceivedDate"
                    @input="menuInvoiceReceivedDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent DP<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageDownPayment"
                  suffix="%"
                  @change="onInputDownPayment"
                  :rules="percentageDpRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Percent PPN<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentagePpn"
                  suffix="%"
                  :rules="[v => v >= 0 || 'PPN must more equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Term <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Due Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDueDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDueDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDueDate"
                    @input="menuInvoiceDueDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.percentagePpn > 0">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountName"
                  return-object
                  v-model="pph"
                  :items="accounts"
                  @change="onChangePph"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Already DP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ totalPercentage }}%</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuTaxDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.taxDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.taxDate"
                    @input="menuTaxDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DPP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.totalDpp"
                  type="number"
                  persistent-hint
                  :hint="formatPrice(form.totalDpp)"
                  :rules="[v => v >= 0 || 'DPP must more than equal 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickProduct"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <!-- <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col> -->
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Bill To</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.billTo }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>FOB</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.fob }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Address</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4">
                      <span>{{ form.address }}</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total PO</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalPoAfterDiscountAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Inv</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Discount</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.discountAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Disc</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterDisc) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Tax PPN</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.taxAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.taxAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Tax PPH</v-card-title>
              <v-card-text class="text-right mt-3">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.pphAmount"
                  @change="onInputTax"
                  persistent-hint
                  :hint="formatPrice(form.pphAmount)"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="checkJournal">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-product
      :dialog="dialog"
      :item="item"
      :index="index"
      @close="close"
      @save="save"
      @deleteItem="deleteItem"
      :accounts="accounts"
    ></dialog-product>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      :currency="form.currency"
    ></dialog-review-journal>
    <dialog-view-table
      :dialog="dialogTablePo"
      :purchaseOrders="form.purchaseOrders"
      @close="close"
      @deleteItem="deleteItemTablePo"
      type="PO"
    ></dialog-view-table>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogProduct from "@/components/DialogProduct";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import DialogViewTable from "@/components/DialogViewTable";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-purchase-down-payment",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-product": DialogProduct,
    DialogReviewJournal,
    DialogViewTable,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      taxDate: moment().format("yyyy-MM-DD"),
      invoiceReceivedDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: moment().format("yyyy-MM-DD"),
      invoiceDuration: 0,
      vendorId: null,
      vendorName: "",
      percentageDownPayment: 0,
      percentagePpn: 11,
      currency: "IDR",
      fob: "",
      percentageUpTo: 0,
      billTo: "",
      address: "",
      taxInvoiceSerialNumber: "",
      remark: "",
      rate: 1,
      totalAmount: 0,
      totalAmountIDR: 0,
      totalPoAmount: 0,
      totalPoAmountIDR: 0,
      totalPoAfterDiscountAmount: 0,
      totalPoAfterDiscountAmountIDR: 0,
      totalPoDiscountAmount: 0,
      totalPoDiscountAmountIDR: 0,
      discountAmount: 0,
      discountAmountIDR: 0,
      taxAmount: 0,
      taxAmountIDR: 0,
      pphAmount: 0,
      pphAmountIDR: 0,
      totalAmountAfterDisc: 0,
      totalAmountAfterDiscIDR: 0,
      totalAmountAfterTax: 0,
      totalAmountAfterTaxIDR: 0,
      totalDpp: 0,
      purchaseOrders: [],
      products: [],
      status: "Outstanding",
      pphNumber: "",
    },
    valid: true,
    menuInvoiceDate: false,
    menuInvoiceDueDate: false,
    menuInvoiceReceivedDate: false,
    menuTaxDate: false,
    form: {},
    items: [],
    vendor: null,
    purchaseOrderVendor: null,
    pph: {},
    purchaseOrders: [],
    address: {},
    accounts: [],
    dialog: false,
    dialogReview: false,
    dialogTablePo: false,
    item: {},
    index: -1,
    journals: [],
    accountNumber: "",
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "productDetail",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "qty",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
    ],
    totalPercentageDP: 0,
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal += key.qty * key.unitPrice;
      });
      return subtotal;
    },

    ...mapState("purchaseDownPayment", ["lastFormNumber"]),
    ...mapState("external", ["vendors"]),
    ...mapState("purchaseDownPayment", ["totalDownPayment"]),
    percentageDpRules() {
      return [
        v => !!v || "Percentage DP is required",
        v =>
          (v <= 100 - this.totalPercentageDP && v >= 1) ||
          `Percentage DP must be more than 1 and less than ${100 - this.totalPercentageDP}`,
      ];
    },
    totalPercentage() {
      let total = 0;
      this.totalDownPayment.forEach(x => {
        total += x.percentage;
      });
      return total;
    },
  },

  watch: {
    totalAmount(val) {
      if (this.purchaseOrderVendor.disc_type === 1) {
        this.form.totalPoDiscountAmount = val * (this.purchaseOrderVendor.disc_value / 100);
        this.form.totalPoDiscountAmountIDR = this.form.totalPoDiscountAmount * this.form.rate;
      } else if (this.purchaseOrderVendor.disc_type === 2) {
        this.form.totalPoDiscountAmount = this.purchaseOrderVendor.disc_value;
        this.form.totalPoDiscountAmountIDR = this.form.totalPoDiscountAmount * this.form.rate;
      }
      this.form.totalPoAfterDiscountAmount = val - this.form.totalPoDiscountAmount;
      this.form.totalPoAfterDiscountAmountIDR =
        this.form.totalPoAfterDiscountAmount * this.form.rate;
      this.form.totalPoAmount = val;
      this.form.totalPoAmountIDR = val * this.form.rate;
      if (this.form.currency != "IDR") {
        this.form.totalAmount = this.form.totalPoAmount * (this.form.percentageDownPayment / 100);
        this.form.discountAmount =
          this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100);
        this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      } else {
        this.form.totalAmount = parseFloat(
          (this.form.totalPoAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.discountAmount = parseFloat(
          (this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.totalAmountAfterDisc = parseFloat(
          (this.form.totalAmount - this.form.discountAmount).toFixed(0)
        );
        this.form.totalAmountAfterTax = parseFloat(
          (this.form.totalAmountAfterDisc + this.form.taxAmount).toFixed(0)
        );
      }
      this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;
      this.form.discountAmountIDR = this.form.discountAmount * this.form.rate;
      this.form.totalAmountAfterDiscIDR = this.form.totalAmountIDR - this.form.discountAmountIDR;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterDiscIDR + this.form.taxAmountIDR;
    },
  },

  methods: {
    checkJournal() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountNumber: this.getAccountNumber(this.form.currency),
            accountName: `UANG MUKA PEMBELIAN ${this.form.currency}`,
            debit: this.form.totalAmountAfterDisc,
            credit: 0,
            debitIDR: this.form.totalAmountAfterDiscIDR,
            creditIDR: 0,
          },
          {
            accountNumber: this.accountNumber,
            accountName: `${this.form.vendorName} ${this.form.currency}`,
            debit: 0,
            credit: this.form.totalAmountAfterTax,
            debitIDR: 0,
            creditIDR: this.form.totalAmountAfterTaxIDR,
          },
        ];
        if (this.form.taxAmount > 0) {
          this.journals.unshift({
            accountNumber: "180.00001",
            accountName: `PAJAK MASUKAN`,
            debit: this.form.taxAmount,
            credit: 0,
            debitIDR: this.form.taxAmountIDR,
            creditIDR: 0,
          });
        }
        if (this.form.pphAmount > 0) {
          this.journals.push({
            accountNumber: this.pph.accountNumber,
            accountName: this.pph.accountName,
            debit: 0,
            credit: this.form.pphAmount,
            debitIDR: 0,
            creditIDR: this.form.pphAmount,
          });
        }
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    async submit() {
      this.form.products = this.items;
      this.form.formNumber = this.lastFormNumber;
      let totalDebit = this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      let totalCredit = this.form.totalAmountAfterTax;

      if (totalDebit.toFixed(2) - totalCredit.toFixed(2) === 0) {
        this.$store.commit("SET_LOADER", true);
        const response = await this.$store.dispatch("purchaseDownPayment/create", this.form);
        if (response.status === 200) {
          this.$store.commit("SET_LOADER", false);
          this.clear();
        }
      } else {
        let different = totalDebit - totalCredit;
        if (different > 0) {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Debit`,
          });
        } else {
          this.$store.dispatch("toast", {
            type: "error",
            message: `Journal not balance with -${Math.abs(different)} on Dredit`,
          });
        }
      }
    },
    calculatePercentage() {
      let total = 0;
      this.totalDownPayment.forEach(x => (total += x.percentage));
      return total;
    },
    async getAP(id) {
      await this.$store
        .dispatch("chartOfAccount/getAP", { id: id, code: this.form.currency })
        .then(response => (this.accountNumber = response.data));
    },
    getAccountNumber() {
      if (this.form.currency === "IDR") {
        return "150.00001";
      } else if (this.form.currency === "SGD") {
        return "150.00002";
      } else if (this.form.currency === "USD") {
        return "150.00003";
      } else if (this.form.currency === "AUD") {
        return "150.00006";
      } else if (this.form.currency === "YEN") {
        return "150.00005";
      } else {
        return "150.00004";
      }
    },
    onChangeVendor(val) {
      this.$store.commit("SET_LOADER", true);
      this.form.vendorId = val.id;
      this.form.vendorName = val.supplier_name;
      this.form.billTo = val.supplier_name;
      Promise.all([this.getPurchaseOrderVendor(val.id), this.getVendorAddress(val.id)])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangePph(val) {
      this.form.pphNumber = val.accountNumber;
    },
    async getPurchaseOrderVendor(id) {
      const response = await this.$store.dispatch("external/getPurchaseOrders", id);
      if (response.status === 200) {
        this.purchaseOrders = response.data;
      }
    },
    async getVendorAddress(id) {
      const response = await this.$store.dispatch("external/getVendorAddress", id);
      if (response.status === 200) {
        this.address = response.data;
        this.form.address =
          response.data.address_line_1 +
          ", " +
          response.data.post_code +
          ", " +
          response.data.city +
          ", " +
          response.data.country_id;
      }
    },
    async addPurchaseOrder() {
      if (this.form.purchaseOrders.findIndex(x => x === this.purchaseOrderVendor) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "PO sudah digunakan",
        });
        // } else if (
        //   this.form.purchaseOrders.findIndex(
        //     x => x.products[0].curr !== this.purchaseOrderVendor.products[0].curr
        //   ) > -1
        // ) {
        //   this.$store.dispatch("toast", {
        //     type: "error",
        //     message: "Currency PO berbeda",
        //   });
      } else {
        await this.$store
          .dispatch("external/getPurchaseOrderProducts", this.purchaseOrderVendor.id)
          .then(async response => {
            if (response.data.length > 0) {
              response.data.forEach(x => (x.poNumber = this.purchaseOrderVendor.name));
              this.form.currency = response.data[0].curr;
              if (response.data[0].curr === "RP") {
                this.form.currency = "IDR";
              }
              if (response.data[0].curr === "S$") {
                this.form.currency = "SGD";
              }
              if (response.data[0].curr === "US$") {
                this.form.currency = "USD";
              }
              this.getRate({ code: response.data[0].curr, date: this.form.invoiceDate });
              this.changeHeader();
              response.data.forEach(x => {
                this.items.push({
                  productId: x.product_id,
                  productDetail: x.products.part_desc,
                  partNumber: x.products.part_num,
                  partName: x.products.part_name,
                  mfr: x.products.mfr,
                  partDesc: x.products.part_desc,
                  qty: x.qty_pos,
                  unitPrice: x.unit_price,
                  unitMeasure: x.products.default_um,
                  discount: x.discount,
                  total: x.qty_pos * x.unit_price,
                  reference: x.reference,
                  poNumber: x.poNumber,
                  doNumber: x.doNumber,
                  department: x.department,
                  accountNumber: x.accountNumber,
                  accountName: x.accountName,
                });
              });
              await this.$store.dispatch(
                "purchaseDownPayment/getTotalDownPayment",
                this.purchaseOrderVendor.id
              );
              this.calculatePercentage();
              let total = response.data
                .map(x => x.unit_price * x.qty_pos)
                .reduce((total, array) => total + array, 0);
              this.purchaseOrderVendor.total = total;
              if (this.purchaseOrderVendor.disc_type == 0) {
                this.purchaseOrderVendor.totalDiscount = 0;
              } else if (this.purchaseOrderVendor.disc_type == 1) {
                this.purchaseOrderVendor.totalDiscount =
                  total * (this.purchaseOrderVendor.disc_value / 100);
              } else if (this.purchaseOrderVendor.disc_type == 2) {
                this.purchaseOrderVendor.totalDiscount = this.purchaseOrderVendor.disc_value;
              }
              this.purchaseOrderVendor.totalAfterDiscount =
                total - this.purchaseOrderVendor.totalDiscount;
              this.form.purchaseOrders.push(this.purchaseOrderVendor);
              this.getAP(this.vendor.id);
            } else {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Product Kosong",
              });
            }
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
          });
      }

      // const response = await this.$store.dispatch(
      //   "external/getPurchaseOrderProducts",
      //   this.purchaseOrderVendor.id
      // );
      // if (response.status === 200) {
      //   if (response.data.length > 0) {
      //     this.form.currency = response.data[0].curr;
      //     if (response.data[0].curr === "RP") {
      //       this.form.currency = "IDR";
      //     }
      //     if (response.data[0].curr === "S$") {
      //       this.form.currency = "SGD";
      //     }
      //     if (response.data[0].curr === "US$") {
      //       this.form.currency = "USD";
      //     }
      //   } else {
      //     this.$store.dispatch("toast", {
      //       type: "error",
      //       message: "Product Kosong",
      //     });
      //   }
    },
    viewTablePo() {
      this.dialogTablePo = true;
    },
    deleteItemTablePo(item) {
      const index = this.form.purchaseOrders.findIndex(x => x === item);
      if (index > -1) {
        this.form.purchaseOrders.splice(index, 1);
        this.items
          .filter(x => x.poNumber === item.name)
          .forEach(x => {
            const index = this.items.indexOf(x);
            if (index > -1) this.items.splice(index, 1);
          });
      }
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceReceivedDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration(val) {
      if (this.form.currency !== "IDR") {
        this.getRate({ code: this.form.currency, date: val });
      } else {
        this.form.rate = 1;
      }
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    async onInputDownPayment() {
      if (this.form.currency != "IDR") {
        this.form.totalAmount = this.form.totalPoAmount * (this.form.percentageDownPayment / 100);
        this.form.discountAmount =
          this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100);
        this.form.totalAmountAfterDisc = this.form.totalAmount - this.form.discountAmount;
        this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
        this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      } else {
        this.form.totalAmount = parseFloat(
          (this.form.totalPoAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.discountAmount = parseFloat(
          (this.form.totalPoDiscountAmount * (this.form.percentageDownPayment / 100)).toFixed(0)
        );
        this.form.totalAmountAfterDisc = parseFloat(
          (this.form.totalAmount - this.form.discountAmount).toFixed(0)
        );
        this.form.taxAmount = parseFloat(
          (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
        );
        this.form.totalAmountAfterTax = parseFloat(
          (this.form.totalAmountAfterDisc + this.form.taxAmount).toFixed(0)
        );
      }
      this.form.totalAmountIDR = this.form.totalAmount * this.form.rate;
      this.form.discountAmountIDR = this.form.discountAmount * this.form.rate;
      this.form.totalAmountAfterDiscIDR = this.form.totalAmountIDR - this.form.discountAmountIDR;
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterDiscIDR + this.form.taxAmountIDR;
      this.form.percentageUpTo = this.form.percentageDownPayment + this.totalPercentageDP;
    },
    onInputPpn() {
      if (this.form.currency != "IDR") {
        this.form.taxAmount = this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100);
      } else {
        this.form.taxAmount = parseFloat(
          (this.form.totalAmountAfterDisc * (this.form.percentagePpn / 100)).toFixed(0)
        );
      }
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterDisc + this.form.taxAmount;
      this.form.totalAmountAfterTaxIDR = this.form.totalAmountAfterDiscIDR + this.form.taxAmountIDR;
    },
    onInputTax() {
      this.form.taxAmountIDR = this.form.taxAmount * this.form.rate;
      this.form.pphAmountIDR = this.form.pphAmount * this.form.rate;
      this.form.totalAmountAfterTax =
        this.form.totalAmountAfterDisc - this.form.pphAmount + this.form.taxAmount;
      this.form.totalAmountAfterTaxIDR =
        this.form.totalAmountAfterDiscIDR - this.form.pphAmountIDR + this.form.taxAmountIDR;
    },
    async getRate(params) {
      this.$store
        .dispatch("currency/getRate", params)
        .then(response => (this.form.rate = response.data));
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    save(item) {
      this.items[this.index] = item;
    },
    close() {
      this.dialog = this.dialogReview = this.dialogTablePo = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickProduct(item) {
      this.item = item;
      this.index = this.items.map(x => x.productId).indexOf(item.productId);
      this.dialog = true;
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
      this.vendor = null;
      this.purchaseOrderVendor = null;
      this.items = [];
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getAccounts(),
        await this.$store.dispatch("purchaseDownPayment/getLastFormNumber"),
        await this.$store.dispatch("external/getVendors"),
      ])
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    changeHeader() {
      this.headers = [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "Product Detail",
          value: "productDetail",
          sortable: false,
          divider: true,
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
        },
        {
          text: "U/M",
          value: "unitMeasure",
          sortable: false,
          divider: true,
        },
        {
          text: "U/Price",
          value: "unitPrice",
          sortable: false,
          divider: true,
        },
        {
          text: "Disc %",
          value: "discount",
          sortable: false,
          divider: true,
        },
        {
          text: `Amount (${this.form.currency})`,
          value: "total",
          sortable: false,
          divider: true,
        },
        {
          text: "PO#",
          value: "poNumber",
          sortable: false,
          divider: true,
        },
        {
          text: "Project",
          value: "project",
          sortable: false,
          divider: true,
        },
        {
          text: "Dept",
          value: "department",
          sortable: false,
          divider: true,
        },
        {
          text: "Reff",
          value: "reference",
          sortable: false,
          divider: true,
        },
      ];
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.remark.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
