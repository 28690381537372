<template>
  <v-dialog v-model="dialog" max-width="1100" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Journal</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="journals"
          disable-pagination
          hide-default-footer
          show-expand
          :expanded.sync="expanded"
          item-key="accountNumber"
        >
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.debit="{ item }">
            {{ formatPrice(item.debit) }}
            {{ currency !== "IDR" ? `(${formatPrice(item.debitIDR)})` : "" }}
          </template>
          <template v-slot:item.credit="{ item }">
            {{ formatPrice(item.credit) }}
            {{ currency !== "IDR" ? `(${formatPrice(item.creditIDR)})` : "" }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="4">TOTAL</td>
              <td style="border: 1px">
                {{ formatPrice(debit) }}
                {{ currency !== "IDR" ? `(${formatPrice(debitIDR)})` : "" }}
              </td>
              <td style="border: 1px">
                {{ formatPrice(credit) }}
                {{ currency !== "IDR" ? `(${formatPrice(creditIDR)})` : "" }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        No
                      </th>
                      <th class="text-left">
                        Account Number
                      </th>
                      <th class="text-left">
                        Account Name
                      </th>
                      <th class="text-left">
                        Nature
                      </th>
                      <th class="text-left">
                        Begin Balance
                      </th>
                      <th class="text-left">
                        End Balance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(x, index) in item.pointers" :key="index + 'a'">
                      <td>{{ index + 1 }}</td>
                      <td>{{ x.accountNumber }}</td>
                      <td>{{ x.accountName }}</td>
                      <td>{{ x.nature }}</td>
                      <td>{{ formatPrice(x.balance) }}</td>
                      <td>{{ formatPrice(calculatePointer(x.nature, x.balance, item)) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-review-journal",
  props: {
    dialog: Boolean,
    journals: Array,
    currency: String,
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account Number",
          value: "accountNumber",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account Name",
          value: "accountName",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Debit",
          value: "debit",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Credit",
          value: "credit",
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    save() {
      this.$emit("save");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    calculatePointer(nature, balance, item) {
      if (nature === "Debit") {
        if (item.debit > 0) {
          return balance + item.debit;
        } else {
          return balance - item.credit;
        }
      } else {
        if (item.credit > 0) {
          return balance + item.credit;
        } else {
          return balance - item.debit;
        }
      }
    },
  },
  computed: {
    debit() {
      let total = 0;
      this.journals.forEach(x => {
        total += parseFloat(x.debit);
      });
      return total;
    },
    credit() {
      let total = 0;
      this.journals.forEach(x => {
        total += parseFloat(x.credit);
      });
      return total;
    },
    debitIDR() {
      let total = 0;
      this.journals.forEach(x => {
        total += parseFloat(x.debitIDR);
      });
      return total;
    },
    creditIDR() {
      let total = 0;
      this.journals.forEach(x => {
        total += parseFloat(x.creditIDR);
      });
      return total;
    },
  },
};
</script>

<style></style>
