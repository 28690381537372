import api from "@/services/api";

const BASE_URL = "/fiscal-month";

export async function fetchFiscalMonths(year) {
  try {
    const response = await api.get(BASE_URL, { year: year });
    return response.data;
  } catch (error) {
    console.error("Error fetching fiscal months", error);
    return [];
  }
}

export function allowedDates(fiscalMonths) {
  return date => {
    const month = date.substr(0, 7); // Extract YYYY-MM part of the date
    const fiscalMonth = fiscalMonths.find(fm => fm.month === month);
    return fiscalMonth && fiscalMonth.status === "Open";
  };
}
