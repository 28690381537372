import { fetchFiscalMonths, allowedDates } from "../dateUtils";

export const fiscalMonthMixin = {
  data() {
    return {
      fiscalMonths: [],
      year: new Date().getFullYear(),
    };
  },
  async created() {
    this.fiscalMonths = await fetchFiscalMonths(this.year);
  },
  methods: {
    getAllowedDates() {
      return allowedDates(this.fiscalMonths);
    },
  },
};
