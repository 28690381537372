<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>Product Detail</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <span>{{ item.productDetail }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4">
                <v-subheader>Unit Measure</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8">
                <span>{{ item.unitMeasure }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Quantity</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.qty"
                  @input="onInputQty"
                ></v-text-field>
                <!-- <span>{{ item.qty }}</span> -->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Unit Price</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.unitPrice"
                  @change="onInputPrice"
                  persistent-hint
                  :hint="formatPrice(item.unitPrice)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{
                  item.discount > 100 ? formatPrice(item.discount) : `${item.discount}%`
                }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>Rp. {{ formatPrice(item.total) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>PO#</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.poNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Project</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.project }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="6" md="6" lg="6" class="py-0">
                <v-subheader>Department</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.department }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Reference</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.reference }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="type === 'Invoice'">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountName"
                  return-object
                  v-model="account"
                  :items="accounts"
                  @change="onChangeAccount"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">
          Close
        </v-btn>
        <v-btn color="red" @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-product",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
    accounts: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    setAccount() {
      return this.item.accountNumber;
    },
  },

  watch: {
    setAccount(val) {
      this.account = this.accounts.find(x => x.accountNumber === val);
    },
  },

  data() {
    return {
      account: {},
    };
  },

  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    calculateTotal() {
      let subtotal = this.item.qty * this.item.unitPrice;
      if (this.item.discount > 100) {
        this.item.total = subtotal - this.item.discount;
      } else {
        this.item.total = subtotal - (this.item.discount / 100) * subtotal;
      }
    },
    onInputQty() {
      this.calculateTotal();
    },
    onInputPrice() {
      this.calculateTotal();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeAccount(val) {
      this.item.accountNumber = val.accountNumber;
      this.item.accountName = val.accountName;
    },
  },
};
</script>

<style></style>
