<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Table</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersPo"
          :items="purchaseOrders"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
          v-if="type === 'PO'"
        >
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="error" icon @click.stop="deleteItem(item)">
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-data-table
          :headers="headersDo"
          :items="deliveryOrders"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
          v-if="type === 'DO'"
        >
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="error" icon @click.stop="deleteItem(item)">
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-view-table",
  props: {
    dialog: Boolean,
    purchaseOrders: Array,
    deliveryOrders: Array,
    type: String,
  },
  data() {
    return {
      headersDo: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "DO Number",
          value: "name",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      headersPo: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "PO Number",
          value: "purchaseOrderNumber",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Site",
          value: "site",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    deleteItem(item) {
      this.$emit("deleteItem", item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
